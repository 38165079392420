<h2>Recover Password</h2>
<div class="container column form-wrapper">
	<form [formGroup]="directorKeyForm">
		<div class="container column form-column">
			<mat-form-field class="grey-magenta-theme">
				<mat-label>Game Code</mat-label>
				<input
					matInput
					formControlName="gameCode" />
			</mat-form-field>
			<mat-form-field class="grey-magenta-theme">
				<mat-label>Email</mat-label>
				<input
					matInput
					formControlName="emailField" />
				<mat-error *ngIf="directorKeyForm.get('emailField').invalid"></mat-error>
				<mat-error
					*ngIf="
						directorKeyForm.get('emailField').hasError('required')
					"></mat-error>
			</mat-form-field>

			<ng-container *ngIf="success">
				<h3>
					Success requesting new director key. Please check your email inbox for new
					key details
				</h3>
			</ng-container>
			<ng-container *ngIf="responseFail">
				<h3>There has been an error requesting a new director key</h3>
				<h4>{{ responseMessage }}</h4>
			</ng-container>
			<button
				*ngIf="success"
				mat-flat-button
				color="primary"
				(click)="onClose()">
				Close
			</button>
			<button
				*ngIf="!success"
				mat-flat-button
				color="primary"
				(click)="onSubmit()">
				Submit
			</button>
		</div>
	</form>
</div>
