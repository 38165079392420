<div class="tab container column">
	<div class="container column actions-row">
		<mat-tab-group
			animationDuration="200ms"
			mat-stretch-tabs="false"
			[attr.mat-align-tabs]="shouldAlignTabsToStart() ? 'start' : 'center'"
			mat-align-tabs="start">
			<mat-tab label="Names">
				<ng-container *ngIf="IDB_DataStatus">
					<app-game-players [isLoading]="isLoading"></app-game-players>
				</ng-container>
			</mat-tab>
			<mat-tab label="Game Actions">
				<app-game-actions></app-game-actions>
			</mat-tab>
		</mat-tab-group>
	</div>
</div>
