<div
	class="container row top"
	[ngClass]="currentBreakpoint">
	<div
		class="container row top details"
		[ngClass]="currentBreakpoint">
		<mat-form-field [ngClass]="{ 'grey-magenta-theme': applyMagentaGreyTheme }">
			<mat-label [ngClass]="{ 'grey-magenta-theme': applyMagentaGreyTheme }"
				>Show Details</mat-label
			>
			<mat-select
				[(ngModel)]="tableOption"
				[ngClass]="{ 'grey-magenta-theme': applyMagentaGreyTheme }"
				name="detail"
				(valueChange)="onOptionSelected($event)">
				<mat-option
					value="none"
					[ngClass]="{ 'grey-magenta-theme': applyMagentaGreyTheme }"
					>None</mat-option
				>
				<mat-option
					[ngClass]="{ 'grey-magenta-theme': applyMagentaGreyTheme }"
					*ngFor="let detail of eventDetails"
					[value]="detail.value"
					>{{ detail.option }}</mat-option
				>
			</mat-select>
		</mat-form-field>
		<div class="container row event-name-row">
			<mat-form-field class="event-name-field">
				<mat-label>Event Name</mat-label>
				<input
					type="text"
					[(ngModel)]="eventName"
					(ngModelChange)="onFormFieldChange($event)"
					matInput />
			</mat-form-field>

			<button
				class="main-save"
				[disabled]="disableSave"
				mat-flat-button
				matSuffix
				color="primary"
				(click)="captureFormData()">
				<!-- {{ getButtonMessage() ? 'Success' : 'Save' }} -->
				{{ getButtonMessage() }}
			</button>
		</div>
	</div>
</div>
<div class="container row table">
	<div
		class="spinner"
		*ngIf="isLoading">
		<mat-spinner></mat-spinner>
	</div>
	<ng-container
		*ngIf="
			(!isLoading && initialTableData && matchType === 'pairs') ||
			matchType === 'individual'
		">
		<app-pairs-table
			[initialTableData]="initialTableData"
			[loadingStatus]="isLoading"></app-pairs-table>
	</ng-container>
	<ng-container *ngIf="!isLoading && initialTableData && matchType === 'teams'">
		<app-teams-table
			[initialTableData]="initialTableData"
			[loadingStatus]="isLoading"
			(startView)="onRecieveDisableSave($event)"></app-teams-table>
	</ng-container>
	<!-- <app-match-tables [initialTableData]="initialTableData"
		(formValuesChanged)="(onTeamTableValueChanged)"></app-match-tables> -->
</div>
<div class="container row main-save-container">
	<h4>Save Line Up</h4>
	<button
		class="main-save"
		mat-flat-button
		[disabled]="disableSave"
		color="primary"
		(click)="captureFormData()">
		{{ getButtonMessage() }}
	</button>
</div>
<div class="container row download">
	<div class="container row link-wrapper">
		<button
			(click)="getPublicLink()"
			id="start_pdf"
			mat-flat-button
			color="accent">
			Get Starting Lineup
		</button>
		<ng-container *ngIf="publicLink">
			<div
				class="container row public-link"
				(click)="copyToClipboard(publicLink)">
				<div class="copy">
					<h3>Click to copy this link!</h3>
					<mat-icon>file_copy</mat-icon>
				</div>
				<div
					class="public-link-text link-text-wrapper"
					[title]="publicLink">
					<a
						class="public-link-text"
						target="_blank"
						rel="noreferrer"
						[href]="publicLink"
						><span class="public-link-text">{{ publicLink }}</span></a
					>
				</div>
			</div>
		</ng-container>
	</div>

	<div class="container row date-wrapper">
		<mat-form-field
			class="date-picker"
			[ngClass]="{ ' grey-magenta-theme': applyMagentaGreyTheme }">
			<mat-label>Forward-Date (Optional) </mat-label>
			<input
				[ngClass]="{ 'grey-magenta-theme:': applyMagentaGreyTheme }"
				type="text"
				[(ngModel)]="forwardDate"
				(ngModelChange)="onFormFieldChange($event)"
				matInput
				[matDatepicker]="date" />
			<mat-datepicker-toggle
				[ngClass]="{ 'grey-magenta-theme': applyMagentaGreyTheme }"
				matIconSuffix
				[for]="date"></mat-datepicker-toggle>
			<mat-datepicker
				#date
				[ngClass]="{
					'grey-magenta-theme': applyMagentaGreyTheme
				}"></mat-datepicker>
		</mat-form-field>

		<button
			class="main-save"
			mat-flat-button
			[disabled]="disableSave"
			color="primary"
			(click)="captureFormData()">
			{{ getButtonMessage() }}
			<!-- {{ buttonText }} -->
		</button>
	</div>
</div>
