<div class="container row details">
	<ng-container *ngIf="authService.isAuthedSubject$ | async">
		<!-- <p>test</p> -->
		<!-- <span class="gamecode">{{ userDetailsService.gameCodeSubject | async }}</span> -->
		<span class="gamecode">{{ gameCode }}</span>

		<!-- <span class="email">{{ userDetailsService.emailSubject | async }}</span> -->
		<!-- <span class="email">{{ email }}</span> -->
	</ng-container>
</div>
