<h2 class="file-name">
	Uploaded File: {{ uploadedFile ? uploadedFile.name : 'FileName' }}
</h2>
<div class="container column main-wrapper">
	<div class="container row main">
		<div class="container column">
			<div class="container header-wrapper">
				<h3>Clear Mapping</h3>
			</div>
			<ul class="clear-list">
				<li *ngFor="let header of finalOurHeaders; let i = index">
					<button
						#clearButton
						class="clear-button"
						mat-flat-button
						color="warn"
						(click)="clearMapping(i)">
						Clear
					</button>
				</li>
			</ul>
		</div>
		<div
			*ngIf="inputFileHeaders.length > 0"
			class="container column">
			<div class="container header-wrapper">
				<h3>Your File Columns</h3>
			</div>
			<ul>
				<li *ngFor="let header of finalOurHeaders; let i = index">
					<select
						name="inputSelectedHeader{{ i }}"
						id=""
						[(ngModel)]="inputSelectedHeaders[i]"
						(ngModelChange)="onInputSelectionChange($event, i)">
						<option
							*ngFor="let headerItem of inputFileHeaders"
							[value]="headerItem.name"
							[disabled]="
								headerItem.selected && headerItem.name !== inputSelectedHeaders[i]
							">
							{{ headerItem.name }}
						</option>
					</select>
				</li>
			</ul>
		</div>

		<div
			class="container column checkbox-col"
			*ngIf="inputFileHeaders.length > 0">
			<div class="container header-wrapper">
				<h3 class="join header">Join with column?</h3>
			</div>
			<ul>
				<li *ngFor="let header of finalOurHeaders; let i = index">
					<mat-checkbox
						#joinColumnCheckbox
						*ngIf="i === 0"
						[(ngModel)]="columnsToJoin[i]"
						[disabled]="inputSelectedHeaders[i] === undefined"></mat-checkbox>
				</li>
			</ul>
		</div>

		<div
			*ngIf="inputFileHeaders.length > 0 && columnsToJoin"
			class="container column">
			<div class="container header-wrapper">
				<h3>Your File Columns</h3>
			</div>
			<ul>
				<li *ngFor="let header of finalOurHeaders; let i = index">
					<select
						[disabled]="!columnsToJoin[i]"
						name="inputSelectedHeader{{ i }}"
						id=""
						[(ngModel)]="inputJoinedHeaders[i]"
						(ngModelChange)="onInputJoinedSelectionChange($event, i)">
						<option
							*ngFor="let headerItem of inputFileHeaders; let j = index"
							[value]="headerItem.name"
							[disabled]="columnsToJoin[i] === columnsToJoin[j] || i === j">
							{{ headerItem.name }}
						</option>
					</select>
				</li>
			</ul>
		</div>

		<div
			class="container column"
			*ngIf="inputFileHeaders.length > 0">
			<div class="container header-wrapper">
				<h3 class="arrow">-></h3>
			</div>
			<ul class="map-to">
				<li
					class="map-to"
					*ngFor="let header of finalOurHeaders">
					Map to
				</li>
			</ul>
		</div>

		<div class="container column">
			<div class="container header-wrapper">
				<h3>Target Columns To Map To</h3>
			</div>
			<ul>
				<li *ngFor="let header of finalOurHeaders; let i = index">
					<select
						[(ngModel)]="outputSelectedHeaders[i]"
						(ngModelChange)="onOutputSelectionChange($event, i)"
						name="outputSelectedHeader{{ i }}"
						id="">
						<option
							*ngFor="let headerItem of finalOurHeaders; let j = index"
							[value]="headerItem.value"
							[disabled]="headerItem.selected">
							{{
								headerItem.name === 'Name'
									? 'Name (FIRST NAME + SURNAME)'
									: headerItem.name
							}}
						</option>
					</select>
				</li>
			</ul>
		</div>

		<div class="container column">
			<div class="container header-wrapper">
				<h3>Save the mapping</h3>
			</div>
			<ul class="save-column">
				<li *ngFor="let header of finalOurHeaders; let i = index">
					<button
						#saveMappingButton
						mat-flat-button
						color="primary"
						(click)="saveMapping(i)">
						Save
					</button>
				</li>
			</ul>
		</div>
		<!-- <div class="container column dev show">
			<div class="container header-wrapper">
				<h3>Selected Mapping</h3>
			</div>
			<ul class="dev display">
				<li
					class="display"
					*ngFor="let header of inputFileHeaders; let i = index">
					<span class="right">
						{{ inputSelectedHeaders[i] }}
					</span>
					<span
						class="and"
						*ngIf="inputJoinedHeaders[i]">
						&nbsp; &amp; &nbsp;{{ inputJoinedHeaders[i] }}</span
					>
					<span
						class="map"
						*ngIf="outputSelectedHeaders[i]">
						&nbsp; mapped to: &nbsp;
					</span>
					<span class="left">
						{{ outputSelectedHeaders[i] }}
					</span>
				</li>
			</ul>
		</div> -->
	</div>
	<!-- <div class="final-display">
		{{ selectedMapping | json }}
	</div> -->
	<div class="container column center import-all">
		<button
			mat-flat-button
			color="primary"
			(click)="onImportClick()">
			Import Database
		</button>
	</div>
</div>
