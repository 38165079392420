<ng-container *ngIf="progress !== 100">
	<div class="progress container column">
		<h3>Loading Database</h3>
		<mat-progress-bar
			mode="determinate"
			[value]="progress"></mat-progress-bar>
	</div>
</ng-container>
<app-current-game></app-current-game>

<!-- <ng-container *ngIf="dbInit && progress === 100">
	<mat-tab-group
	[(selectedIndex)]="selectedTabIndex"
		animationDuration="0ms"
		(selectedTabChange)="onTabChange($event)">
		<mat-tab
			class="tab"
			label="Current Game">
			<ng-template matTabContent>
				<ng-container *ngTemplateOutlet="currentGameTab"></ng-container>
			</ng-template>
		</mat-tab>
		<mat-tab
			class="tab"
			label="Historic Games">
			<ng-template matTabContent>
				<ng-container *ngTemplateOutlet="historicGamesTab"></ng-container>
			</ng-template>
		</mat-tab>
		<mat-tab
			class="tab"
			label="Game Database">
			<ng-template matTabContent>
				<ng-container *ngTemplateOutlet="databaseLanding"></ng-container>
			</ng-template>
		</mat-tab>
	</mat-tab-group>
</ng-container>

<ng-template #currentGameTab>
	<app-current-game *ngIf="selectedTabIndex === 0"></app-current-game>
</ng-template>

<ng-template #historicGamesTab>
	<app-historic-games *ngIf="selectedTabIndex === 1"></app-historic-games>
</ng-template>

<ng-template #databaseLanding>
	<app-database-landing *ngIf="selectedTabIndex === 2"></app-database-landing>
</ng-template> -->

