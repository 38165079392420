<div class="container column">
	<table class="hand-table">
		<thead>
			<tr>
				<th></th>
				<th><mat-icon svgIcon="clubs-card"></mat-icon></th>
				<th><mat-icon svgIcon="diamonds-card"></mat-icon></th>
				<th><mat-icon svgIcon="hearts-card"></mat-icon></th>
				<th><mat-icon svgIcon="spades-card"></mat-icon></th>

				<th>N/T</th>
			</tr>
		</thead>
		<tbody>
			<tr>
				<th></th>
				<td>{{ handData[1] }}</td>
				<td>{{ handData[2] }}</td>
				<td>{{ handData[3] }}</td>
				<td>{{ handData[4] }}</td>
				<td>{{ handData[5] }}</td>
			</tr>
			<tr>
				<th>North</th>
				<td>{{ handData[6] }}</td>
				<td>{{ handData[7] }}</td>
				<td>{{ handData[8] }}</td>
				<td>{{ handData[9] }}</td>
				<td>{{ handData[10] }}</td>
			</tr>
			<tr>
				<th>South</th>
				<td>{{ handData[11] }}</td>
				<td>{{ handData[12] }}</td>
				<td>{{ handData[13] }}</td>
				<td>{{ handData[14] }}</td>
				<td>{{ handData[15] }}</td>
			</tr>
			<tr>
				<th>East</th>
				<td>{{ handData[16] }}</td>
				<td>{{ handData[17] }}</td>
				<td>{{ handData[18] }}</td>
				<td>{{ handData[19] }}</td>
				<td>{{ handData[20] }}</td>
			</tr>
			<tr>
				<th>West</th>
				<td>{{ handData[21] }}</td>
				<td>{{ handData[22] }}</td>
				<td>{{ handData[23] }}</td>
				<td>{{ handData[24] }}</td>
				<td>{{ handData[25] }}</td>
			</tr>
		</tbody>
	</table>

	<!-- <div class="container column">
		<div class="container row top">
			<div class="container column left">
				<div class="container row spade">
					<mat-icon svgIcon="spades-card"></mat-icon>
					<div class="container suit"></div>
					<div class="container count"></div>
				</div>
				<div class="container row heart">
					<mat-icon svgIcon="hearts-card"></mat-icon>
					<div class="container suit"></div>
					<div class="container count"></div>
				</div>
				<div class="container row diamond">
					<mat-icon svgIcon="diamonds-card"></mat-icon>
					<div class="container suit"></div>
					<div class="container count"></div>
				</div>
				<div class="container row club">
					<mat-icon svgIcon="clubs-card"></mat-icon>
					<div class="container suit"></div>
					<div class="container count"></div>
				</div>
			</div>
			<div class="container column middle">
				<div class="container row spade">
					<mat-icon svgIcon="spades-card"></mat-icon>
					<div class="container suit"></div>
					<div class="container count"></div>
				</div>
				<div class="container row heart">
					<mat-icon svgIcon="hearts-card"></mat-icon>
					<div class="container suit"></div>
					<div class="container count"></div>
				</div>
				<div class="container row diamond">
					<mat-icon svgIcon="diamonds-card"></mat-icon>
					<div class="container suit"></div>
					<div class="container count"></div>
				</div>
				<div class="container row club">
					<mat-icon svgIcon="clubs-card"></mat-icon>
					<div class="container suit"></div>
					<div class="container count"></div>
				</div>
			</div>
			<div class="container column right">
				<div class="container row spade">
					<mat-icon svgIcon="spades-card"></mat-icon>
					<div class="container suit"></div>
					<div class="container count"></div>
				</div>
				<div class="container row heart">
					<mat-icon svgIcon="hearts-card"></mat-icon>
					<div class="container suit"></div>
					<div class="container count"></div>
				</div>
				<div class="container row diamond">
					<mat-icon svgIcon="diamonds-card"></mat-icon>
					<div class="container suit"></div>
					<div class="container count"></div>
				</div>
				<div class="container row club">
					<mat-icon svgIcon="clubs-card"></mat-icon>
					<div class="container suit"></div>
					<div class="container count"></div>
				</div>
			</div>
		</div>
		<div class="container row middle">
			<div class="container column left">
				<div class="container row spade">
					<mat-icon svgIcon="spades-card"></mat-icon>
					<div class="container suit"></div>
					<div class="container count"></div>
				</div>
				<div class="container row heart">
					<mat-icon svgIcon="hearts-card"></mat-icon>
					<div class="container suit"></div>
					<div class="container count"></div>
				</div>
				<div class="container row diamond">
					<mat-icon svgIcon="diamonds-card"></mat-icon>
					<div class="container suit"></div>
					<div class="container count"></div>
				</div>
				<div class="container row club">
					<mat-icon svgIcon="clubs-card"></mat-icon>
					<div class="container suit"></div>
					<div class="container count"></div>
				</div>
			</div>
			<div class="container hand-indictor column middle">
				<div class="hand-number"></div>
				<div class="dealer"></div>
				<div class="vulnerable"></div>
			</div>
			<div class="container column right">
				<div class="container row spade">
					<mat-icon svgIcon="spades-card"></mat-icon>
					<div class="container suit"></div>
					<div class="container count"></div>
				</div>
				<div class="container row heart">
					<mat-icon svgIcon="hearts-card"></mat-icon>
					<div class="container suit"></div>
					<div class="container count"></div>
				</div>
				<div class="container row diamond">
					<mat-icon svgIcon="diamonds-card"></mat-icon>
					<div class="container suit"></div>
					<div class="container count"></div>
				</div>
				<div class="container row club">
					<mat-icon svgIcon="clubs-card"></mat-icon>
					<div class="container suit"></div>
					<div class="container count"></div>
				</div>
			</div>
		</div>
		<div class="container row bottom">
			<div class="container column left">
				<div class="container row spade">
					<mat-icon svgIcon="spades-card"></mat-icon>
					<div class="container suit"></div>
					<div class="container count"></div>
				</div>
				<div class="container row heart">
					<mat-icon svgIcon="hearts-card"></mat-icon>
					<div class="container suit"></div>
					<div class="container count"></div>
				</div>
				<div class="container row diamond">
					<mat-icon svgIcon="diamonds-card"></mat-icon>
					<div class="container suit"></div>
					<div class="container count"></div>
				</div>
				<div class="container row club">
					<mat-icon svgIcon="clubs-card"></mat-icon>
					<div class="container suit"></div>
					<div class="container count"></div>
				</div>
			</div>
			<div class="container column middle">
				<div class="container row spade">
					<mat-icon svgIcon="spades-card"></mat-icon>
					<div class="container suit"></div>
					<div class="container count"></div>
				</div>
				<div class="container row heart">
					<mat-icon svgIcon="hearts-card"></mat-icon>
					<div class="container suit"></div>
					<div class="container count"></div>
				</div>
				<div class="container row diamond">
					<mat-icon svgIcon="diamonds-card"></mat-icon>
					<div class="container suit"></div>
					<div class="container count"></div>
				</div>
				<div class="container row club">
					<mat-icon svgIcon="clubs-card"></mat-icon>
					<div class="container suit"></div>
					<div class="container count"></div>
				</div>
			</div>
			<div class="container column right">
				<div class="container row spade">
					<mat-icon svgIcon="spades-card"></mat-icon>
					<div class="container suit"></div>
					<div class="container count"></div>
				</div>
				<div class="container row heart">
					<mat-icon svgIcon="hearts-card"></mat-icon>
					<div class="container suit"></div>
					<div class="container count"></div>
				</div>
				<div class="container row diamond">
					<mat-icon svgIcon="diamonds-card"></mat-icon>
					<div class="container suit"></div>
					<div class="container count"></div>
				</div>
				<div class="container row club">
					<mat-icon svgIcon="clubs-card"></mat-icon>
					<div class="container suit"></div>
					<div class="container count"></div>
				</div>
			</div>
		</div>
	</div> -->

	<style>
		.hand.column {
			margin-top: 3rem;
		}
		.hand.row {
			gap: 2rem;
		}
	</style>

	<div class="container hand column">
		<div class="container hand row">
			<div class="left container column">
				<h3>North</h3>
				<div class="1">
					<div class="container row">
						<div class="suit">Spades</div>
						<div class="count">count</div>
					</div>
				</div>
				<div class="2">
					<div class="container row">
						<div class="suit">Hearts</div>
						<div class="count">count</div>
					</div>
				</div>
				<div class="3">
					<div class="container row">
						<div class="suit">Diamonds</div>
						<div class="count">count</div>
					</div>
				</div>
				<div class="4">
					<div class="container row">
						<div class="suit">Clubs</div>
						<div class="count">count</div>
					</div>
				</div>
			</div>
			<div class="middle">
				<h3>South</h3>
				<div class="1">
					<div class="container row">
						<div class="suit">Spades</div>
						<div class="count">count</div>
					</div>
				</div>
				<div class="2">
					<div class="container row">
						<div class="suit">Hearts</div>
						<div class="count">count</div>
					</div>
				</div>
				<div class="3">
					<div class="container row">
						<div class="suit">Diamond</div>
						<div class="count">count</div>
					</div>
				</div>
				<div class="4">
					<div class="container row">
						<div class="suit">Clubs</div>
						<div class="count">count</div>
					</div>
				</div>
			</div>
			<div class="right container column">
				<h3>East</h3>
				<div class="1">
					<div class="container row">
						<div class="suit">Spades</div>
						<div class="count">count</div>
					</div>
				</div>
				<div class="2">
					<div class="container row">
						<div class="suit">Hearts</div>
						<div class="count">count</div>
					</div>
				</div>
				<div class="3">
					<div class="container row">
						<div class="suit">Diamonds</div>
						<div class="count">count</div>
					</div>
				</div>
				<div class="4">
					<div class="container row">
						<div class="suit">Clubs</div>
						<div class="count">count</div>
					</div>
				</div>
			</div>
			<div class="right container column">
				<h3>West</h3>
				<div class="1">
					<div class="container row">
						<div class="suit">Spades</div>
						<div class="count">count</div>
					</div>
				</div>
				<div class="2">
					<div class="container row">
						<div class="suit">Hearts</div>
						<div class="count">count</div>
					</div>
				</div>
				<div class="3">
					<div class="container row">
						<div class="suit">Diamonds</div>
						<div class="count">count</div>
					</div>
				</div>
				<div class="4">
					<div class="container row">
						<div class="suit">Clubs</div>
						<div class="count">count</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="hand-indicator container column">
		<div class="hand-number"></div>
		<div class="dealer"></div>
		<div class="vulnerable"></div>
	</div>
</div>
