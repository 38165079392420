<h2>{{ isEdit ? 'Edit ' : 'Add New ' }}Player</h2>
<div class="container column center dialog-content">
	<mat-form-field [ngClass]="{ 'grey-magenta-theme': applyMagentaGreyTheme }">
		<mat-label [ngClass]="{ 'grey-magenta-theme': applyMagentaGreyTheme }"
			>Name</mat-label
		>
		<input
			matInput
			[(ngModel)]="playerName" />
	</mat-form-field>
	<mat-form-field [ngClass]="{ 'grey-magenta-theme': applyMagentaGreyTheme }">
		<mat-label>Email</mat-label>
		<input
			[disabled]="!playerName"
			[(ngModel)]="playerEmail"
			matInput
			[ngClass]="{ 'grey-magenta-theme': applyMagentaGreyTheme }" />
	</mat-form-field>
	<mat-form-field [ngClass]="{ 'grey-magenta-theme': applyMagentaGreyTheme }">
		<mat-label [ngClass]="{ 'grey-magenta-theme': applyMagentaGreyTheme }"
			>Telephone</mat-label
		>
		<input
			[disabled]="!playerName"
			[ngClass]="{ 'grey-magenta-theme': applyMagentaGreyTheme }"
			matInput
			[(ngModel)]="playerPhone" />
	</mat-form-field>
	<h4>Player IDs (Check any that apply)</h4>
	<div class="container row checkbox-row">
		<mat-checkbox
			[disabled]="!playerName"
			[(ngModel)]="ebuChecked"
			[ngClass]="{ 'grey-magenta-theme': applyMagentaGreyTheme }"
			>EBU</mat-checkbox
		>
		<mat-form-field
			*ngIf="ebuChecked"
			[ngClass]="{ 'grey-magenta-theme': applyMagentaGreyTheme }">
			<mat-label [ngClass]="{ 'grey-magenta-theme': applyMagentaGreyTheme }"
				>ID Code</mat-label
			>
			<input
				[disabled]="!playerName"
				[ngClass]="{ 'grey-magenta-theme': applyMagentaGreyTheme }"
				matInput
				[(ngModel)]="ebuId" />
		</mat-form-field>
	</div>
	<div class="container row checkbox-row">
		<mat-checkbox
			[disabled]="!playerName"
			[(ngModel)]="bboChecked"
			[ngClass]="{ 'grey-magenta-theme': applyMagentaGreyTheme }"
			>BBO</mat-checkbox
		>
		<mat-form-field
			*ngIf="bboChecked"
			[ngClass]="{ 'grey-magenta-theme': applyMagentaGreyTheme }">
			<mat-label [ngClass]="{ 'grey-magenta-theme': applyMagentaGreyTheme }"
				>ID Code</mat-label
			>
			<input
				[disabled]="!playerName"
				matInput
				[(ngModel)]="bboId"
				[ngClass]="{ 'grey-magenta-theme': applyMagentaGreyTheme }" />
		</mat-form-field>
	</div>

	<mat-form-field [ngClass]="{ 'grey-magenta-theme': applyMagentaGreyTheme }">
		<mat-label [ngClass]="{ 'grey-magenta-theme': applyMagentaGreyTheme }"
			>Date added</mat-label
		>
		<input
			[disabled]="!playerName"
			[ngClass]="{ 'grey-magenta-theme': applyMagentaGreyTheme }"
			matInput
			[(ngModel)]="dateAdded"
			type="text" />
	</mat-form-field>
	<div
		class="container dialog-actions"
		mat-dialog-actions>
		<div class="container row button-wrapper">
			<button
				[disabled]="!playerName"
				(click)="onSave()"
				mat-flat-button
				color="primary"
				class="save">
				Save
			</button>
			<button
				(click)="onCancel()"
				mat-flat-button
				color="alert"
				class="cancel">
				Cancel
			</button>
		</div>
	</div>
</div>
