<mat-toolbar
	*ngIf="!isSuperAdmin"
	color="accent"
	class="toolbar">
	<ng-container *ngIf="isPublicLink">
		<div class="container row public-view">
			<span class="header-text">IBEScore Companion</span>
			<div class="logo-container">
				<img
					class="logo"
					src="assets/images/logo/globe-cards-icon.png"
					alt="Globe cards icon." />
			</div>
		</div>
	</ng-container>
	<ng-container *ngIf="!isPublicLink">
		<div
			class="container row view"
			[ngClass]="currentBreakpoint + (authed ? ' authed' : '')">
			<ng-container *ngIf="authService.isAuthedSubject$ | async">
				<button
					[disabled]="!this.isNavLoaded"
					class="menu"
					mat-flat-button
					(click)="toggleSidenav()">
					<!-- <mat-icon>menu</mat-icon> -->
					Menu
				</button>
			</ng-container>

			<span class="header-text">IBEScore Companion</span>

			<div class="header-right container">
				<button
					*ngIf="authService.isAuthedSubject$ | async"
					class="button-white refresh"
					mat-flat-button
					color="primary"
					(click)="refreshDatabase()">
					<mat-icon
						class="icon"
						svgIcon="rotate"></mat-icon>
				</button>
				<button
					mat-flat-button
					color="warn"
					routerLink="/forgot-password"
					*ngIf="!(authService.isAuthedSubject$ | async) && !isPublicLink"
					(click)="forgotPassword()">
					Forgot Password
				</button>
				<ng-container
					*ngIf="
						(authService.isAuthedSubject$ | async) &&
						currentBreakpoint !== 'handset' &&
						currentBreakpoint !== 'medium'
					">
					<app-user-details></app-user-details>
				</ng-container>
				<ng-container
					*ngIf="currentBreakpoint !== 'handset' && currentBreakpoint !== 'medium'">
					<button
						mat-flat-button
						color="warn"
						routerLink="/auth/logout"
						*ngIf="authService.isAuthedSubject$ | async"
						(click)="logout()">
						Logout
					</button>
				</ng-container>
			</div>
		</div>
	</ng-container>
</mat-toolbar>
<!--  -->
<!--  -->
<!--  -->
<!--  -->
<header *ngIf="isSuperAdmin">
	<nav class="header">
		<div class="wrapper block">
			<mat-toolbar
				color="accent"
				class="toolbar">
				<!-- tool bar content -->
				<ng-container *ngIf="isPublicLink">
					<div class="container row public-view">
						<span class="header-text">IBEScore Companion</span>
						<div class="logo-container">
							<img
								class="logo"
								src="assets/images/logo/globe-cards-icon.png"
								alt="Globe cards icon." />
						</div>
					</div>
				</ng-container>
				<ng-container *ngIf="!isPublicLink">
					<div
						class="container row view space-between nav-wrapper"
						[ngClass]="currentBreakpoint + (authed ? ' authed' : '')">
						<ng-container *ngIf="authed">
							<div
								class="logo-container container row"
								[ngClass]="currentBreakpoint">
								<img
									class="logo"
									src="assets/images/logo/globe-cards-icon.png"
									alt="Globe cards icon." />
								<a routerLink="/admin/welcome-members">
									<mat-icon
										class="icon"
										svgIcon="home"
										routerLink="/admin/welcome-members"></mat-icon>
								</a>
							</div>
							<ng-container
								*ngIf="
									currentBreakpoint !== 'handset' && currentBreakpoint !== 'medium'
								">
								<app-user-details></app-user-details>
							</ng-container>
							<app-header-nav #topBar></app-header-nav>
							<div
								class="container row nav-right"
								[ngClass]="currentBreakpoint">
								<button
									mat-flat-button
									class="button-white hamburger"
									color="primary"
									(click)="toggleDrawer()">
									<mat-icon
										class="icon"
										svgIcon="menu"></mat-icon>
								</button>
							</div>
							<ng-container
								*ngIf="
									currentBreakpoint !== 'handset' && currentBreakpoint !== 'medium'
								">
								<button
									mat-flat-button
									color="warn"
									routerLink="/auth/logout"
									*ngIf="authService.isAuthedSubject$ | async"
									(click)="logout()">
									Logout
								</button>
							</ng-container>
							<!-- header-nav.component.html -->
							<!-- test drawer -->
						</ng-container>
					</div>
				</ng-container>
			</mat-toolbar>
		</div>
		<!-- drawer -->
		<div class="drawer-container">
			<div
				class="drawer"
				[ngClass]="currentBreakpoint"
				#drawer
				[style.height]="height">
				<!-- Drawer content goes here -->
				<app-header-nav-drawer
					(emitClose)="toggleDrawer()"
					(emitLogout)="logout()"
					(emitRefresh)="refreshDatabase()"></app-header-nav-drawer>
			</div>
		</div>
	</nav>
	<div class="masthead">
		<!-- welcome to ibescore -->
	</div>
</header>
