<div class="container row pagination">
	<button
		mat-flat-button
		color="warn"
		(click)="navigateToPage(currentHandPage - 1)"
		[disabled]="currentHandPage === 1">
		Previous
	</button>
	<span
		><h3>Current Hand: {{ currentHandPage }}</h3></span
	>
	<button
		mat-flat-button
		color="primary"
		(click)="navigateToPage(currentHandPage + 1)"
		[disabled]="currentHandPage === totalPages">
		Next
	</button>
</div>
