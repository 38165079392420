<div
	class="main"
	*ngIf="initialTableData">
	<div class="button-wrapper order-wrapper container row">
		<button
			mat-flat-button
			class="order-button"
			[ngClass]="currentBreakpoint"
			[color]="teamOrder ? 'primary' : 'accent'"
			(click)="onSwitchOrder()">
			{{ teamOrder ? 'Show Start Order' : 'Show Team Order' }}
		</button>
		<span *ngIf="!teamOrder"
			><h4>Editing not permitted in starting matchup view</h4></span
		>
	</div>
	<form
		[formGroup]="teamsForm"
		(ngSubmit)="onSubmit()">
		<div class="table-wrapper">
			<table *ngIf="!tableConfig.timesLunch">
				<tr class="header-row">
					<th>{{ teamOrder ? 'Team' : 'Table' }} Number</th>
					<!-- <th>Pair Number</th> -->
					<th
						class="conditional"
						*ngIf="tableConfig.venues">
						Venues
					</th>
					<th
						*ngIf="tableConfig.boardCol"
						class="conditional empty">
						Colours
					</th>
					<ng-container *ngIf="teamOrder">
						<th class="Team Name">Team Name</th>
					</ng-container>

					<th class="north">North</th>
					<th class="divider"></th>
					<th class="south">South</th>
					<!-- <th
						*ngIf="tableConfig.stratification"
						class="conditional empty">
						Stratification
					</th> -->

					<!-- <th
						*ngIf="tableConfig.adjustments"
						class="conditional empty">
						Adjust
					</th> -->
					<!-- <th
						*ngIf="tableConfig.handicaps"
						class="conditional empty">
						Handicap
					</th> -->
					<!-- <th
						*ngIf="tableConfig.labels"
						class="conditional empty">
						Labels
					</th> -->
					<!-- <th
						*ngIf="tableConfig.abbrev"
						class="conditional empty">
						Abbreviations
					</th> -->
					<th class="split divider"></th>
					<!-- <th>Pair Number</th> -->
					<th class="east">East</th>
					<th class="divider"></th>
					<th class="west">West</th>
					<th
						*ngIf="tableConfig.stratification"
						class="conditional empty">
						Stratification
					</th>
					<th
						*ngIf="tableConfig.sitters"
						class="conditional empty sitters">
						Sitters
					</th>
					<th
						*ngIf="tableConfig.adjustments"
						class="conditional empty">
						Adjust
					</th>
					<th
						*ngIf="tableConfig.handicaps"
						class="conditional empty">
						Handicaps
					</th>
					<th
						*ngIf="tableConfig.labels"
						class="conditional empty">
						Labels
					</th>
					<th
						*ngIf="tableConfig.abbrev"
						class="conditional empty">
						Abbreviations
					</th>
				</tr>
				<ng-container *ngFor="let tableNumber of tableNumbers; let i = index">
					<ng-container *ngIf="i % teamsPerSide === 0">
						<tr class="side-row">
							<td></td>
							<td
								class="side-label"
								[attr.colspan]="currentShownCols">
								<div class="side-label-wrapper container row">
									<label for="side">Side {{ i / teamsPerSide + 1 }}</label>
									<input
										name="side"
										[formControlName]="getSideLabelControl(i)" />
								</div>
							</td>
						</tr>
					</ng-container>
					<tr
						class="tables-row"
						*ngIf="teamsForm?.get(tableNumber)"
						[formGroupName]="tableNumber">
						<td>{{ i + 1 }}</td>
						<!-- Pair Number -->
						<!-- <td *ngFor="let column of ['nsPairs']">
							<input [formControlName]="column" />
						</td> -->
						<!-- Venues -->
						<ng-container *ngIf="tableConfig.venues">
							<td
								class="conditional"
								*ngFor="let column of ['venues']">
								<input
									[attr.disabled]="disableControls"
									[formControlName]="column" />
							</td>
						</ng-container>
						<ng-container *ngIf="tableConfig.boardCol">
							<td
								class="conditional"
								*ngFor="let column of ['boardCol']">
								<input
									[attr.disabled]="disableControls"
									[formControlName]="column" />
							</td>
						</ng-container>
						<ng-container *ngIf="teamOrder">
							<td *ngFor="let column of ['team_name']">
								<input
									[formControlName]="column"
									[attr.disabled]="disableControls" />
							</td>
						</ng-container>

						<td *ngFor="let column of ['north']">
							<input
								[attr.disabled]="disableControls"
								[formControlName]="column" />
						</td>
						<td class="divider">&</td>
						<td *ngFor="let column of ['south']">
							<input
								[attr.disabled]="disableControls"
								[formControlName]="column" />
						</td>
						<!-- <ng-container *ngIf="tableConfig.stratification">
							<td
								class="conditional"
								*ngFor="let column of ['ns_stratification']">
								<input [formControlName]="column" />
							</td>
						</ng-container> -->

						<!-- <ng-container *ngIf="tableConfig.adjustments">
							<td
								class="conditional"
								*ngFor="let column of ['ns_adjustments']">
								<input [formControlName]="column" />
							</td>
						</ng-container> -->
						<!-- <ng-container *ngIf="tableConfig.handicaps">
							<td
								class="conditional"
								*ngFor="let column of ['ns_handicaps']">
								<input [formControlName]="column" />
							</td>
						</ng-container> -->
						<!-- <ng-container *ngIf="tableConfig.labels">
							<td
								class="conditional"
								*ngFor="let column of ['ns_labels']">
								<input [formControlName]="column" />
							</td>
						</ng-container> -->
						<!-- <ng-container *ngIf="tableConfig.abbrev">
							<td
								class="conditional"
								*ngFor="let column of ['ns_abbrev']">
								<input [formControlName]="column" />
							</td>
						</ng-container> -->

						<td class="divider split"></td>
						<!-- <td *ngFor="let column of ['ewPairs']">
							<input [formControlName]="column" />
						</td> -->
						<td *ngFor="let column of ['east']">
							<input
								[attr.disabled]="disableControls"
								[formControlName]="column" />
						</td>
						<td class="divider">&</td>
						<td *ngFor="let column of ['west']">
							<input
								[attr.disabled]="disableControls"
								[formControlName]="column" />
						</td>
						<ng-container *ngIf="tableConfig.stratification">
							<td
								class="conditional"
								*ngFor="let column of ['ew_stratification']">
								<input
									[attr.disabled]="disableControls"
									[formControlName]="column" />
							</td>
						</ng-container>
						<ng-container *ngIf="tableConfig.sitters">
							<td
								class="conditional sitters"
								*ngFor="let column of ['ew_sitters']">
								<input
									[attr.disabled]="disableControls"
									[formControlName]="column"
									type="checkbox" />
							</td>
						</ng-container>
						<ng-container *ngIf="tableConfig.adjustments">
							<td
								class="conditional"
								*ngFor="let column of ['ew_adjustments']">
								<input
									[attr.disabled]="disableControls"
									[formControlName]="column" />
							</td>
						</ng-container>
						<ng-container *ngIf="tableConfig.handicaps">
							<td
								class="conditional"
								*ngFor="let column of ['ew_handicaps']">
								<input
									[attr.disabled]="disableControls"
									[formControlName]="column" />
							</td>
						</ng-container>
						<ng-container *ngIf="tableConfig.labels">
							<td
								class="conditional"
								*ngFor="let column of ['ew_labels']">
								<input
									[attr.disabled]="disableControls"
									[formControlName]="column" />
							</td>
						</ng-container>
						<ng-container *ngIf="tableConfig.abbrev">
							<td
								class="conditional"
								*ngFor="let column of ['ew_abbrev']">
								<input
									[formControlName]="column"
									[attr.disabled]="disableControls" />
							</td>
						</ng-container>
					</tr>
				</ng-container>
			</table>
			<table *ngIf="tableConfig.timesLunch">
				<tr>
					<th>Table Number</th>
					<th>Timings From</th>
					<th>Timings To</th>
					<th>Lunch</th>
				</tr>
				<ng-container *ngFor="let tableNumber of tableNumbers; let i = index">
					<tr
						*ngIf="teamsForm?.get(tableNumber.toString())"
						[formGroupName]="tableNumber.toString()">
						<td class="table-no">{{ i + 1 }}</td>
						<td
							*ngFor="let column of ['time_from']"
							class="timings">
							<!-- <input [formControlName]="column" /> -->
							<ngx-timepicker-field
								[controlOnly]="true"
								[formControlName]="column"
								#pickerFrom></ngx-timepicker-field>
						</td>
						<td
							*ngFor="let column of ['time_to']"
							class="timings">
							<!-- <input [formControlName]="column" /> -->
							<ngx-timepicker-field
								[controlOnly]="true"
								[formControlName]="column"
								#pickerTo></ngx-timepicker-field>
						</td>
						<td
							*ngFor="let column of ['lunch']"
							class="lunch">
							<input
								class="lunch"
								[formControlName]="column"
								type="checkbox" />
						</td>
					</tr>
				</ng-container>
			</table>
		</div>
	</form>
</div>
