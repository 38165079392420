<div class="upload-container">
	<ng-container *ngIf="data">
		<h2>{{ data.title }}</h2>
		<h4>{{ data.message }}</h4>
	</ng-container>
	<div
		#dragBox
		class="container column dragbox upload"
		(dragover)="onDragOver($event)"
		(drop)="onDrop($event)"
		(dragleave)="onDragLeave($event)">
		<label
			for="upload"
			class="custom-file">
			<span>Drag or drop file here for upload, or click to browse</span>
			<input
				id="upload"
				multiple
				#fileInput
				name="upload"
				color="primary"
				type="file"
				(change)="onChange($event)"
				(click)="fileInput.value = null"
				style="display: none" />
			<mat-icon>cloud_upload</mat-icon>
		</label>

		<div class="container column file-list">
			<ul>
				<li *ngFor="let file of selectedFiles">{{ file.name }}</li>
			</ul>
		</div>
	</div>
	<div class="container column center">
		<ng-container
			class="contaienr column center"
			*ngIf="isLoading && !uploadSuccess">
			<mat-spinner></mat-spinner
		></ng-container>
		<ng-container *ngIf="!isLoading && !uploadSuccess">
			<div class="container row button-wrapper">
				<button
					*ngIf="selectedFiles.length > 0"
					mat-flat-button
					color="primary"
					(click)="onUpload()">
					Upload
				</button>
				<button
					*ngIf="selectedFiles.length > 0"
					mat-flat-button
					color="warn"
					(click)="clearFiles()">
					Clear
				</button>
			</div>
		</ng-container>
		<ng-container *ngIf="uploadSuccess && !isLoading">
			<h3>
				Hand config file has been uploaded successfully. Please refresh database to
				see latest changes.
			</h3>
			<button
				mat-flat-button
				color="accent"
				(click)="onUploadMore()">
				Upload Again
			</button>
		</ng-container>
	</div>
</div>
