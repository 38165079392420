<form [formGroup]="loginForm">
	<mat-form-field>
		<input
			matInput
			formControlName="gameCode"
			placeholder="Game Code" />
	</mat-form-field>
	<mat-form-field>
		<input
			[type]="hide ? 'password' : 'text'"
			matInput
			formControlName="key"
			placeholder="Director Key" />
		<mat-icon
			matSuffix
			(click)="hide = !hide">
			{{ hide ? 'visibility_off' : 'visibility' }}
		</mat-icon>
	</mat-form-field>
	<button
		*ngIf="!loginClicked"
		[disabled]="!loginForm.valid"
		class="login"
		mat-flat-button
		color="accent"
		(click)="onSubmit()">
		Login
	</button>
	<ng-container *ngIf="loginClicked">
		<mat-spinner class="login-spinner"></mat-spinner>
	</ng-container>
</form>
