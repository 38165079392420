<div class="container column main">
	<form
		[formGroup]="pairsForm"
		(ngSubmit)="onSubmit()">
		<div class="table-wrapper">
			<table
				*ngIf="!tableConfig.timesLunch"
				class="main-table">
				<!-- <div class="header-flex"> -->
				<thead>
					<tr class="header-row">
						<!-- <div class="north-south"> -->
						<th class="small table-number">Table Number</th>
						<ng-container *ngIf="matchType === 'pairs'">
							<th class="small">Pair Number</th>
						</ng-container>
						<th
							class="conditional"
							*ngIf="tableConfig.venues">
							Venues
						</th>
						<th
							*ngIf="tableConfig.boardCol"
							class="conditional empty">
							Colours
						</th>
						<ng-container *ngIf="matchType === 'individual'">
							<th>
								{{ currentBreakpoint === 'handset' ? 'Player No.' : 'Player No.' }}
							</th>
							<ng-container *ngIf="tableConfig.sitters"
								><th>Sitters</th>
							</ng-container>
						</ng-container>

						<th class="north">North</th>
						<ng-container
							*ngIf="matchType === 'individual' && tableConfig.handicaps">
							<th>Handicap</th>
						</ng-container>

						<th class="divider"></th>
						<ng-container *ngIf="matchType === 'individual'">
							<th>
								{{ currentBreakpoint === 'handset' ? 'Player No.' : 'Player No.' }}
							</th>
							<ng-container *ngIf="tableConfig.sitters"
								><th>Sitters</th>
							</ng-container>
						</ng-container>

						<th class="south">South</th>
						<ng-container
							*ngIf="matchType === 'individual' && tableConfig.handicaps">
							<th>Handicap</th>
						</ng-container>
						<th
							*ngIf="tableConfig.stratification"
							class="conditional empty">
							Stratification
						</th>
						<th
							*ngIf="tableConfig.sitters && !individual"
							class="conditional empty sitters">
							Sitters
						</th>
						<th
							*ngIf="tableConfig.adjustments"
							class="conditional empty">
							Adjust
						</th>
						<th
							*ngIf="tableConfig.handicaps && !individual"
							class="conditional empty">
							Handicap
						</th>
						<th
							*ngIf="tableConfig.labels"
							class="conditional empty">
							Labels
						</th>
						<th
							*ngIf="tableConfig.abbrev"
							class="conditional empty">
							Abbreviations
						</th>
						<!-- </div> -->
						<th class="split divider"></th>
						<ng-container *ngIf="matchType === 'pairs'">
							<th class="small">Pair Number</th>
						</ng-container>
						<!-- <div class="east-west"> -->
						<ng-container *ngIf="matchType === 'individual'">
							<th>
								{{ currentBreakpoint === 'handset' ? 'Player No.' : 'Player No.' }}
							</th>
							<ng-container *ngIf="tableConfig.sitters"
								><th>Sitters</th>
							</ng-container>
						</ng-container>
						<th class="east">East</th>
						<ng-container
							*ngIf="matchType === 'individual' && tableConfig.handicaps">
							<th>Handicap</th>
						</ng-container>
						<th class="divider"></th>
						<ng-container *ngIf="matchType === 'individual'">
							<th>
								{{ currentBreakpoint === 'handset' ? 'Player No.' : 'Player No.' }}
							</th>
							<ng-container *ngIf="tableConfig.sitters"
								><th>Sitters</th>
							</ng-container>
						</ng-container>
						<th class="west">West</th>
						<ng-container
							*ngIf="matchType === 'individual' && tableConfig.handicaps">
							<th>Handicap</th>
						</ng-container>
						<th
							*ngIf="tableConfig.stratification"
							class="conditional empty">
							Stratification
						</th>
						<th
							*ngIf="tableConfig.sitters && !individual"
							class="conditional empty sitters">
							Sitters
						</th>
						<th
							*ngIf="tableConfig.adjustments"
							class="conditional empty">
							Adjust
						</th>
						<th
							*ngIf="tableConfig.handicaps && !individual"
							class="conditional empty">
							Handicap
						</th>
						<th
							*ngIf="tableConfig.labels"
							class="conditional empty">
							Labels
						</th>
						<th
							*ngIf="tableConfig.abbrev"
							class="conditional empty">
							Abbreviations
						</th>
						<!-- </div> -->
					</tr>
				</thead>
				<!-- </div> -->
				<!-- <div class="flex"> -->
				<tbody>
					<ng-container
						*ngFor="let tableNumber of getTableNumbers(); let i = index">
						<tr
							class="tables-row"
							*ngIf="pairsForm?.get(tableNumber.toString())"
							[formGroupName]="tableNumber.toString()">
							<!-- <div class="north-south"> -->
							<td class="small table-number">{{ i + 1 }}</td>
							<!-- Pair Number -->
							<ng-container *ngIf="matchType === 'pairs'">
								<td
									*ngFor="let column of ['nsPairs']"
									class="small pair-number">
									<input
										class="table-input"
										[formControlName]="column" />
								</td>
							</ng-container>
							<!-- Venues -->
							<ng-container *ngIf="tableConfig.venues">
								<td
									class="conditional"
									*ngFor="let column of ['venues']">
									<input
										class="table-input"
										[formControlName]="column" />
								</td>
							</ng-container>
							<ng-container *ngIf="tableConfig.boardCol">
								<td
									class="conditional"
									*ngFor="let column of ['boardCol']">
									<input
										class="table-input"
										[formControlName]="column" />
								</td>
							</ng-container>
							<ng-container *ngIf="matchType === 'individual'">
								<td>
									{{ individualNumbers.north[i] }}
								</td>
								<ng-container *ngIf="tableConfig.sitters">
									<td
										class="conditional sitters"
										*ngFor="let column of ['n_sitters']">
										<input
											class="table-input"
											[formControlName]="column"
											type="checkbox" />
									</td>
								</ng-container>
							</ng-container>

							<td *ngFor="let column of ['north']">
								<input
									class="table-input"
									[formControlName]="column" />
							</td>
							<ng-container *ngIf="individual && tableConfig.handicaps">
								<td
									class="conditional handicaps"
									*ngFor="let column of ['n_handi']">
									<input
										class="table-input"
										[formControlName]="column"
										type="checkbox" />
								</td>
							</ng-container>
							<td class="divider">&</td>
							<ng-container *ngIf="matchType === 'individual'">
								<td>
									{{ individualNumbers.south[i] }}
								</td>
								<ng-container *ngIf="tableConfig.sitters">
									<td
										class="conditional sitters"
										*ngFor="let column of ['s_sitters']">
										<input
											class="table-input"
											[formControlName]="column"
											type="checkbox" />
									</td>
								</ng-container>
							</ng-container>
							<td *ngFor="let column of ['south']">
								<input
									class="table-input"
									[formControlName]="column" />
							</td>
							<ng-container *ngIf="individual && tableConfig.handicaps">
								<td
									class="conditional handicaps"
									*ngFor="let column of ['s_handi']">
									<input
										class="table-input"
										[formControlName]="column"
										type="checkbox" />
								</td>
							</ng-container>
							<ng-container *ngIf="tableConfig.stratification">
								<td
									class="conditional"
									*ngFor="let column of ['ns_stratification']">
									<input
										class="table-input"
										[formControlName]="column" />
								</td>
							</ng-container>

							<ng-container *ngIf="tableConfig.sitters && !individual">
								<td
									class="conditional sitters"
									*ngFor="let column of ['ns_sitters']">
									<input
										class="table-input"
										[formControlName]="column"
										type="checkbox" />
								</td>
							</ng-container>
							<ng-container *ngIf="tableConfig.adjustments">
								<td
									class="conditional"
									*ngFor="let column of ['ns_adjustments']">
									<input
										class="table-input"
										[formControlName]="column" />
								</td>
							</ng-container>
							<ng-container *ngIf="tableConfig.handicaps">
								<td
									class="conditional"
									*ngFor="let column of ['ns_handicaps']">
									<input
										class="table-input"
										[formControlName]="column" />
								</td>
							</ng-container>
							<ng-container *ngIf="tableConfig.labels">
								<td
									class="conditional"
									*ngFor="let column of ['ns_labels']">
									<input
										class="table-input"
										[formControlName]="column" />
								</td>
							</ng-container>
							<ng-container *ngIf="tableConfig.abbrev">
								<td
									class="conditional"
									*ngFor="let column of ['ns_abbrev']">
									<input
										class="table-input"
										[formControlName]="column" />
								</td>
							</ng-container>
							<!-- </div> -->
							<td class="divider split"></td>
							<ng-container *ngIf="matchType === 'pairs'">
								<td
									*ngFor="let column of ['ewPairs']"
									class="small pair-number">
									<input
										class="table-input"
										[formControlName]="column" />
								</td>
							</ng-container>
							<!-- <div class="east-west"> -->
							<ng-container *ngIf="matchType === 'individual'">
								<td>
									{{ individualNumbers.east[i] }}
								</td>
								<ng-container *ngIf="tableConfig.sitters">
									<td
										class="conditional sitters"
										*ngFor="let column of ['e_sitters']">
										<input
											class="table-input"
											[formControlName]="column"
											type="checkbox" />
									</td>
								</ng-container>
							</ng-container>
							<td *ngFor="let column of ['east']">
								<input
									class="table-input"
									[formControlName]="column" />
							</td>
							<ng-container *ngIf="individual && tableConfig.handicaps">
								<td
									class="conditional handicaps"
									*ngFor="let column of ['n_handi']">
									<input
										class="table-input"
										[formControlName]="column"
										type="checkbox" />
								</td>
							</ng-container>
							<td class="divider">&</td>
							<ng-container *ngIf="matchType === 'individual'">
								<td>
									{{ individualNumbers.west[i] }}
								</td>
								<ng-container *ngIf="tableConfig.sitters">
									<td
										class="conditional sitters"
										*ngFor="let column of ['w_sitters']">
										<input
											class="table-input"
											[formControlName]="column"
											type="checkbox" />
									</td>
								</ng-container>
							</ng-container>
							<td *ngFor="let column of ['west']">
								<input
									class="table-input"
									[formControlName]="column" />
							</td>
							<ng-container *ngIf="individual && tableConfig.handicaps">
								<td
									class="conditional handicaps"
									*ngFor="let column of ['n_handi']">
									<input
										class="table-input"
										[formControlName]="column"
										type="checkbox" />
								</td>
							</ng-container>
							<ng-container *ngIf="tableConfig.stratification">
								<td
									class="conditional"
									*ngFor="let column of ['ew_stratification']">
									<input
										class="table-input"
										[formControlName]="column" />
								</td>
							</ng-container>
							<ng-container *ngIf="tableConfig.sitters && !individual">
								<td
									class="conditional sitters"
									*ngFor="let column of ['ew_sitters']">
									<input
										class="table-input"
										[formControlName]="column"
										type="checkbox" />
								</td>
							</ng-container>
							<ng-container *ngIf="tableConfig.adjustments">
								<td
									class="conditional"
									*ngFor="let column of ['ew_adjustments']">
									<input
										class="table-input"
										[formControlName]="column" />
								</td>
							</ng-container>
							<ng-container *ngIf="tableConfig.handicaps">
								<td
									class="conditional"
									*ngFor="let column of ['ew_handicaps']">
									<input
										class="table-input"
										[formControlName]="column" />
								</td>
							</ng-container>
							<ng-container *ngIf="tableConfig.labels">
								<td
									class="conditional"
									*ngFor="let column of ['ew_labels']">
									<input
										class="table-input"
										[formControlName]="column" />
								</td>
							</ng-container>
							<ng-container *ngIf="tableConfig.abbrev">
								<td
									class="conditional"
									*ngFor="let column of ['ew_abbrev']">
									<input
										class="table-input"
										[formControlName]="column" />
								</td>
							</ng-container>
							<!-- </div> -->
						</tr>
					</ng-container>
				</tbody>
				<!-- </div> -->
			</table>
			<!-- <ng-container *ngIf="currentBreakpoint === 'handset'">
				<div class="mobile-only">
					<table>
						<tr>
							<th class="north">North</th>
							<th class="South">South</th>
						</tr>
						<tr>
							<tbody></tbody>
						</tr>
					</table>
					<table>
						<tr>
							<th class="east">East</th>
							<th class="west">West</th>
						</tr>
						<tr>
							<tbody></tbody>
						</tr>
					</table>
				</div>
			</ng-container> -->
			<table *ngIf="tableConfig.timesLunch">
				<tr>
					<th>Table Number</th>
					<th>Timings From</th>
					<th>Timings To</th>
					<th>Lunch</th>
				</tr>
				<ng-container *ngFor="let tableNumber of getTableNumbers(); let i = index">
					<tr
						*ngIf="pairsForm?.get(tableNumber.toString())"
						[formGroupName]="tableNumber.toString()">
						<td class="table-no">{{ i + 1 }}</td>
						<td
							*ngFor="let column of ['time_from']"
							class="timings">
							<!-- <input class="table-input" [formControlName]="column" /> -->
							<ngx-timepicker-field
								[controlOnly]="true"
								[formControlName]="column"
								#pickerFrom></ngx-timepicker-field>
						</td>
						<td
							*ngFor="let column of ['time_to']"
							class="timings">
							<!-- <input class="table-input" [formControlName]="column" /> -->
							<ngx-timepicker-field
								[controlOnly]="true"
								[formControlName]="column"
								#pickerTo></ngx-timepicker-field>
						</td>
						<td
							*ngFor="let column of ['lunch']"
							class="lunch">
							<input
								class="table-input"
								class="lunch"
								[formControlName]="column"
								type="checkbox" />
						</td>
					</tr>
				</ng-container>
			</table>
		</div>
	</form>
</div>
